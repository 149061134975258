const selfData = {
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [{
                label: "姓名",
                prop: "USERNAME"
            },
            {
                label: "性别",
                prop: "SEX"
            },
            {
                label: "手机号",
                prop: "MOBILE"
            },
            {
                label: "工作概况",
                prop: "GZGK"
            }, {
                label: "工作起始时间",
                prop: "GZQSSJ",
                substr: true
            }, {
                label: "工作结束时间",
                prop: "GZJSSJ",
                substr: true
            }, {
                label: "备注",
                prop: "BZ"
            },
                // {
                //     label: "遗产要素编码",
                //     prop: "YCYSBM"
                // }, {
                //     label: "遗产要素组成",
                //     prop: "YCYSZC"
                // }, {
                //     label: "入库时间",
                //     prop: "RKSJ"
                // },
            ],
        },
    },
    snPage: {
        tiaoshu: 20,
        count: 0,
        currentPage: 1,
        operateType: 'page'
    },
    snSearch: {
        selectData: [{
            label: '',
            clearable: true,
            placeholder: "请选择岗位状态",
            list: [],
            optionLabel: 'Name',
            optionValue: 'Code',
            value: 'gwzt',
            operateType: 'search',
            isShow: true
        }],
    },
};
export { selfData };