<template>
    <div>
        <sn-table-group
            :tableGroupAttributes="tableGroupAttributes"
            @handleChange="handleChange"
        ></sn-table-group>
    </div>
</template>
<script>
import { commonData } from "./sn-table-group-common";
import { selfData } from "./sn-table-group-forest";
import commonMethodMixin from "./commonMethodMixin";
import { mapActions } from "vuex";
export default {
    name: "",
    mixins: [commonMethodMixin],
    data() {
        return {
            commonData,
            selfData,
            infoPage: "/forestPeopleInfo",
            keyword: "", // 关键字
            departmentValue: "",
            roleValue: "",
            gwzt: "", //岗位状态
        };
    },
    mounted() {
        this.getGwztList();
    },
    methods: {
        ...mapActions(["GetEnumItem"]),

        // 获取岗位状态
        async getGwztList(id, done, instance) {
            let res = await this.GetEnumItem({
                enumid: "5e1c9a48-a956-11eb-b3ce-000c2977b7fd",
            });
            this.tableGroupAttributes.snSearch.selectData[0].list = res;
        },
    },
};
</script>
<style lang="scss" scoped></style>
